<template>
    <div class="my-5 pt-2 summary-container">
        <div class="text-lead text-capitalize font-weight-light">
            Your summary total:
            <DecimalPrice :price="previewTotals.total + previewTotals.taxesAndFees" />
        </div>
        <div class="year">per year</div>
        <div v-if="previewTotals.minimumEnforced" class="minimum-required">
            This is Jewelers Mutual Group's minimum policy premium.
            <b-icon
                :id="`show-tooltip-summary-minimum`"
                class="bootstrap-icon minimum-tooltip-icon"
                icon="question-circle-fill"
            />
            <Tooltip
                :target="`show-tooltip-summary-minimum`"
                title="The minimum premium is the lowest amount insurance regulators allow us to charge based on your state."
            />
        </div>
        <div class="summary-actions mt-4 w-100">
            <b-link class="email-me text-capitalize no-select" @click="emailMe">
                Email me my quote
            </b-link>
            <Button text="Apply for coverage" type="button" @clickEvent="$emit('apply')" :disabled="disabled" />
        </div>
        <div class="w-100">
            <span class="back" @click="$emit('back')">&lt;&nbsp;Back</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/FormGroups/Button';
import DecimalPrice from '@/components/Common/DecimalPrice';
import Tooltip from '@/components/Common/Tooltip';

export default {
    name: 'QuoteSummarySection',
    components: { DecimalPrice, Button, Tooltip },
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: mapGetters(['previewTotals']),
    methods: {
        emailMe() {
            this.$eventBus.$emit('email');
        }
    }
};
</script>

<style lang="scss" scoped>
.summary-container {
    border-top: 2px solid $gray92;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .summary-actions {
        display: flex;
        justify-content: space-between;

        .email-me {
            font-weight: 700;
            text-decoration: underline;
            color: $blue;
        }
    }

    .back {
        color: $blue;
        font-size: 18px;
        cursor: pointer;
        user-select: none;
    }
}
.minimum-required {
    color: red;
    font-size: 9px;
    .minimum-tooltip-icon {
        font-size: 12px;
    }
}
</style>
