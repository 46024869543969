<template>
    <div>
        <b-col class="question-title font-weight-bold" cols="12">
            <span v-if="!appraisal">3.</span>
            Choose your deductible
        </b-col>
        <div>
            <b-link class="linkable pr-3" @click="showDeductibleModal">What is a deductible?</b-link>
            <b-link class="linkable" @click="showPremiumModal">What is a premium?</b-link>
        </div>
        <div class="mt-3">
            <Deductible :loading-quotes="loadingQuotes" />
        </div>

        <b-modal v-model="deductibleModal" size="md" title="What is a deductible?">
            <div>
                <p>
                    An amount of money that you have to pay for something before an insurance company replaces the item.
                </p>
                <p>
                    When your deductible increases, your premium decreases and vice versa.
                </p>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <b-button size="lg" class="float-right" @click="showDeductibleModal">
                        Ok
                    </b-button>
                </div>
            </template>
        </b-modal>
        <b-modal v-model="premiumModal" size="md" title="What is a premium?">
            <div>
                <p>
                    The amount paid to maintain insurance coverage, typically annually.
                </p>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <b-button size="lg" class="float-right" @click="showPremiumModal">
                        Ok
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Deductible from '@/components/Quote/Deductible';

export default {
    name: 'Deductibles',
    props: {
        loadingQuotes: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: { Deductible },
    data: () => ({
        deductibleModal: false,
        premiumModal: false
    }),
    computed: {
        ...mapGetters(['appraisal'])
    },
    methods: {
        showDeductibleModal() {
            this.deductibleModal = !this.deductibleModal;
        },
        showPremiumModal() {
            this.premiumModal = !this.premiumModal;
        }
    }
};
</script>

<style lang="scss" scoped>
.question-title {
    padding-left: 0;
    padding-right: 0;
}

.linkable {
    color: $blue;
    user-select: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
</style>
