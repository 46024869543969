<template>
    <span class="blue-dot">
        &bull;
    </span>
</template>

<script>
export default {
    name: 'Bullet'
};
</script>

<style lang="scss" scoped>
.blue-dot {
    color: #0097d6;
    font-size: 36px;
    vertical-align: middle;
}
</style>
