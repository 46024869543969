<template>
    <PopupModal v-if="firstJewelry" id="add-more-jewelries" size="lg" title="Did You Forget Something?">
        <b-row align-h="center" class="text-center">
            <b-col class="text-lowercase" cols="12">
                Many people who insure their
                <b>{{ firstJewelry.name }}</b>
                with us, also insure another
                <span v-html="otherJewelries" />
            </b-col>
            <b-col class="py-5" cols="12">
                <b-row class="cross-sell-row" no-gutters>
                    <b-col
                        v-for="item in crossItems"
                        :key="item.text"
                        :class="{ 'selected-item': item.selected }"
                        class="cross-sell-item no-select"
                        cols="12"
                        sm="2"
                        @click="toggleItem(item)"
                    >
                        <div class="cross-sell-item-image">
                            <b-img
                                v-if="item.selected"
                                :alt="item.text"
                                :src="require(`@/assets/jewelries/${item.selectedSrc}`)"
                                center
                            />
                            <b-img v-else :alt="item.text" :src="require(`@/assets/jewelries/${item.src}`)" center />
                        </div>
                        <div :class="{ 'selected-text': item.selected }" class="text-under-image">
                            {{ item.text }}
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12 button-w">
                <Button text="Add selected item" type="button" @clickEvent="add" />
            </b-col>
            <b-col class="py-5" cols="12">
                <span class="link no-select" @click="forceApplyForCoverage">
                    No thanks, Continue to Apply for Coverage
                </span>
            </b-col>
        </b-row>
    </PopupModal>
</template>

<script>
import PopupModal from '@/components/Common/PopupModal';
import { mapGetters } from 'vuex';
import constants from '@/constants';
import Button from '@/components/FormGroups/Button';

export default {
    name: 'AddMoreItemsPopup',
    components: { PopupModal, Button },
    props: {
        otherJewelries: {
            type: String,
            required: true
        }
    },
    data: () => ({
        crossItems: constants.jewelryItems
            .filter(jewelry => jewelry.src && jewelry.selectedSrc)
            .map(item => ({ ...item, selected: false }))
    }),
    computed: {
        ...mapGetters(['jewelryItems']),
        firstJewelry() {
            return this.jewelryItems[0];
        }
    },
    methods: {
        toggleItem(item) {
            item.selected = !item.selected;
        },
        add() {
            this.$eventBus.$emit('go-initial-step', this.crossItems);
        },
        forceApplyForCoverage() {
            this.$eventBus.$emit('apply-for-coverage');
        }
    }
};
</script>

<style lang="scss" scoped>
.cross-sell-row {
    justify-content: space-around;
}

.cross-sell-item {
    height: 80px;
    border: 1px solid $pacific-blue;
    margin: 5px;
    position: relative;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-under-image {
    color: $pacific-blue;
}

.selected-text {
    color: $white;
}

.selected-item {
    background-color: $pacific-blue;
}

.link {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
}

.button-w {
    button {
        width: 40%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}
</style>
