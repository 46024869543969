<template>
    <b-modal :id="id" :size="size" :title="title" hide-footer no-close-on-backdrop no-close-on-esc>
        <slot />
    </b-modal>
</template>

<script>
export default {
    name: 'PopupModal',
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        size: {
            type: String,
            required: false,
            validator: value => ['xl', 'lg', 'sm'].includes(value),
            default: 'lg'
        }
    }
};
</script>

<style scoped></style>
