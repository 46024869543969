<template>
    <b-col class="my-2" cols="6" lg="3">
        <b-card class="no-select text-uppercase text-center py-2 pointer">
            <div class="card-text"><LoadingSpinner /></div>
        </b-card>
    </b-col>
</template>

<script>
import LoadingSpinner from '@/components/Common/LoadingSpinner';
export default {
    name: 'DeductibleCardPlaceholder',
    components: { LoadingSpinner }
};
</script>

<style lang="scss" scoped>
.border-primary {
    border-color: $blue !important;
}

.card {
    min-height: 168px;
    border-radius: 0;
    border-color: $light-gray;
    background-color: $white-smoke;
    position: relative;

    .card-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .card-text {
        text-align: center;
    }
}
</style>
