<template>
    <div>
        <b-row v-for="(jewelry, i) in jewelryItems" :key="i" no-gutters>
            <b-col class="pl-5 d-flex deductible-col" cols="12">
                <Bullet class="p" />
                <span class="name p font-weight-bold">{{ jewelry.name }}</span>
                <span class="value p">
                    valued at
                    <DecimalPrice :price="jewelry.value" />
                </span>
                <span class="icon p">
                    <b-icon class="h5 pointer" icon="x-circle" @click="remove(i)" />
                </span>
            </b-col>
            <b-row class="w-100 deductibles-container" no-gutters>
                <DeductibleCardPlaceholder v-if="loadingQuotes && (!jewelry.quotes || !jewelry.quotes.length)" />
                <div v-else-if="!jewelry.quotes || !jewelry.quotes.length" class="no-quotes">
                    We are unable to retrieve your quote at this time. Please try again later. If this problem persists,
                    contact us at
                    <b-link href="tel:888-346-9464">888-346-9464</b-link>
                    or via
                    <b-link href="mailto:Personaljewelry@jminsure.com">email</b-link>
                    .
                </div>
                <template v-else v-for="(deductible, j) in jewelry.quotes">
                    <DeductibleCard
                        v-show="showAll[i] || j < maxAutoShownIndex"
                        :key="j"
                        :deductible="deductible"
                        :jewelry-index="i"
                        :deductible-index="j"
                        class="px-2"
                        @changeSelected="changeSelected"
                    />
                </template>
            </b-row>
            <div
                v-if="jewelry.quotes && jewelry.quotes.length > maxAutoShownIndex"
                class="mt-3 ml-4 linkable no-select font-weight-normal"
                @click="toggleAll(i)"
            >
                <b-icon :icon="showAll[i] ? 'chevron-up' : 'chevron-down'" />
                <span class="text-capitalize pl-2">Show&nbsp;{{ showAll[i] ? 'fewer' : 'all' }}&nbsp;deductibles</span>
            </div>
        </b-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeductibleCardPlaceholder from '@/components/Quote/DeductibleCardPlaceholder';
import DeductibleCard from '@/components/Quote/DeductibleCard';
import DecimalPrice from '@/components/Common/DecimalPrice';
import Bullet from '@/components/Common/Bullet';

export default {
    name: 'Deductible',
    components: { Bullet, DecimalPrice, DeductibleCard, DeductibleCardPlaceholder },
    props: {
        loadingQuotes: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        showAll: [],
        maxAutoShownIndex: 4
    }),
    computed: {
        ...mapGetters(['jewelryItems'])
    },
    methods: {
        ...mapActions(['setJewelryItems', 'removeJewelry']),
        toggleAll(index) {
            this.$set(this.showAll, index, !this.showAll[index]);
        },
        remove(jewelryIndex) {
            if (this.jewelryItems.length) {
                this.removeJewelry(jewelryIndex);
            }
        },
        changeSelected({ jewelryIndex, optionId }) {
            const jewelryItems = this.jewelryItems;
            const foundJewelry = jewelryItems[jewelryIndex];
            const { quotes } = foundJewelry;
            const alreadySelected = quotes.find(d => d.selected);
            alreadySelected.selected = false;

            quotes[optionId].selected = true;

            this.setJewelryItems(jewelryItems);
        }
    },
    beforeMount() {
        this.showAll = this.jewelryItems.map(() => false);
    }
};
</script>

<style lang="scss" scoped>
.deductible-col {
    align-items: center;
    padding: 20px 0;

    .p {
        margin-left: 10px;
    }

    .name {
        font-size: 16px;
    }

    .value {
        color: $light-gray;
    }

    .pointer {
        margin-left: 10px;
        vertical-align: middle;
        color: $comet;
    }

    .icon {
        svg {
            position: relative;
            top: 4px;
        }
    }
}

.no-quotes {
    color: red;
}

.linkable {
    color: $blue;
    cursor: pointer;

    &:hover {
        font-weight: 700;
    }
}
</style>
