<template>
    <div>
        <h1 class="text-capitalize my-3 center-mobile">What's covered</h1>
        <p class="coverage-paragraph">
            We provide all types of jewelry insurance, including engagement ring insurance. Watches, earrings, and even
            loose stones while being set - yep, we cover those too.
        </p>
        <p class="coverage-paragraph">
            No matter the type of jewelry you insure, it'll be protected by our comprehensive repair or replacement
            coverage.&nbsp;
            <b>
                Plus, your coverage includes worldwide travel.
            </b>
        </p>
        <b-row>
            <b-col v-for="coverage in getCoverageOptions" :key="coverage.title" class="coverage pr-3" lg="6">
                <div v-if="coverage.content" :style="bindCss(coverage)" class="coverage-container">
                    <p class="title coverage-paragraph">{{ coverage.title }}</p>
                    <p class="content coverage-paragraph">{{ coverage.content }}</p>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import coverageOptions from '@/constants/coverageOptions';
export default {
    name: 'WhatsCovered',
    computed: {
        getCoverageOptions() {
            return coverageOptions;
        }
    },
    methods: {
        bindCss({ color }) {
            return `border-top: 2px solid ${color};`;
        }
    }
};
</script>

<style lang="scss" scoped>
.coverage-paragraph {
    margin-bottom: $margin-under-element - 10px;
}

.row {
    margin: $margin-under-element 0;
}

.coverage {
    padding: $margin-under-element - 8px 0;

    .coverage-container {
        padding-top: $margin-under-element;

        .title {
            font-weight: 700;
            margin-bottom: $margin-under-element - 10px;
        }
    }
}
</style>
