<template>
    <div>
        <TitleForRetrieveLink />
        <template v-if="(!loadingQuotes || hasAnyQuotes) && firstJewelry">
            <Deductibles :loading-quotes="loadingQuotes" />
            <QuoteSummarySection @apply="apply" @back="back" :disabled="!hasAllQuotes" />
        </template>
        <div v-if="loadingQuotes" class="loading">
            <div class="spinner">
                <loading-spinner />
            </div>
            <div>
                Loading Quotes
            </div>
        </div>
        <WhatsCovered />
        <HomeOwnerComparison />
        <div class="mt-5 text-center">
            <Button text="Apply For Coverage" type="button" @clickEvent="apply" :disabled="!hasAllQuotes" />
            <p class="mt-3">
                Applying for insurance usually takes less than 10 minutes, even on a mobile device.
                <br />
                Apply now and wear your jewelry worry free.
            </p>
        </div>

        <AddMoreItemsPopup :other-jewelries="otherJewelriesForPopup" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import utils from '@/utils';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import WhatsCovered from '@/components/Common/WhatsCovered';
import HomeOwnerComparison from '@/components/Quote/HomeOwnerComparison';
import Button from '@/components/FormGroups/Button';
import TitleForRetrieveLink from '@/components/TitleForRetrieveLink';
import Deductibles from '@/components/Quote/Deductibles';
import QuoteSummarySection from '@/components/Quote/QuoteSummarySection';
import AddMoreItemsPopup from '@/components/ApplicantAndWearer/AddMoreItemsPopup';

export default {
    name: 'Quote',
    components: {
        AddMoreItemsPopup,
        QuoteSummarySection,
        Deductibles,
        TitleForRetrieveLink,
        HomeOwnerComparison,
        WhatsCovered,
        Button,
        LoadingSpinner
    },
    data: () => ({
        otherJewelriesForPopup: '',
        popupAlreadyShown: false,
        loadingQuotes: false
    }),
    async mounted() {
        if (!this.previewQuoteLocation || !this.previewQuoteLocation.zipCode) {
            this.$router.replace('/');
            return;
        }
        window.scrollTo(0, 0);
        this.$eventBus.$on('apply-for-coverage', () => {
            const {
                state: { isTotalLowerThanMinimumPolicyPremium }
            } = this.$store;

            if (isTotalLowerThanMinimumPolicyPremium && !this.popupAlreadyShown) {
                this.showPopupAndRenderContent();

                return;
            }

            this.$router.push('/applicant');
        });

        this.loadingQuotes = true;
        this.loadPreviewQuotes()
            .catch(() => {})
            .then(() => {
                this.loadingQuotes = false;
            });
    },
    beforeDestroy() {
        this.$eventBus.$off('apply-for-coverage');
        this.$eventBus.$off('go-initial-step');
    },
    computed: {
        ...mapGetters(['resultStep', 'jewelryItems', 'previewQuoteLocation']),
        hasAnyQuotes() {
            return this.jewelryItems.find(item => !!(item.quotes && item.quotes.length));
        },
        hasAllQuotes() {
            return !this.jewelryItems.find(item => !item.quotes || !item.quotes.length);
        },
        firstJewelry() {
            return this.jewelryItems[0];
        }
    },
    methods: {
        ...mapActions(['loadPreviewQuotes']),
        showPopupAndRenderContent() {
            const { name: notIncludedJewelry } = this.firstJewelry;

            this.popupAlreadyShown = true;
            this.otherJewelriesForPopup = utils.getJewelryNamesWithSentence(notIncludedJewelry, 2);

            this.$bvModal.show('add-more-jewelries');
        },
        apply() {
            this.$eventBus.$emit('apply-for-coverage');
        },
        back() {
            this.$eventBus.$emit('go-initial-step');
        }
    }
};
</script>

<style scoped lang="scss">
.loading {
    height: 150px;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
}
</style>
