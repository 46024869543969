<template>
    <div>
        <h1 class="mb-3 center-mobile">How We Compare to Homeowners</h1>
        <p>
            Jewelry is typically covered under renters and homeowners insurance policies. But that coverage is not
            usually enough to cover the full replacement cost, and even a jewelry-specific rider added to your
            homeowners policy has its limits.&nbsp;
            <b>
                Insuring your jewelry with a standalone policy protects your home insurance from the effects of a claim.
            </b>
        </p>
        <div v-if="table.items.length > 0">
            <b-table-simple class="d-sm-none">
                <b-tbody>
                    <template v-for="item in table.items">
                        <b-tr :key="`${item.first}-head`">
                            <b-td class="text-center" colspan="2">
                                <b>{{ item.first }}</b>
                            </b-td>
                        </b-tr>
                        <b-tr :key="`${item.first}-body`">
                            <b-td class="text-center w-50">
                                <div class="font-weight-bold text-nowrap">
                                    {{ table.fields[1].label }}
                                </div>
                                <div v-if="typeof item.second === 'string'" class="mobile-small">
                                    {{ item.second }}
                                </div>
                                <b-icon
                                    v-else
                                    :class="item.second ? 'blue' : 'dark-gray'"
                                    :icon="item.second ? 'check-circle-fill' : 'x-circle-fill'"
                                    class="h4 mt-2"
                                />
                            </b-td>
                            <b-td class="text-center w-50">
                                <div class="font-weight-bold">{{ table.fields[2].label }}</div>
                                <div v-if="typeof item.third === 'string'" class="mobile-small">
                                    {{ item.third }}
                                </div>
                                <b-icon
                                    v-else
                                    :class="item.third ? 'blue' : 'dark-gray'"
                                    :icon="item.third ? 'check-circle-fill' : 'x-circle-fill'"
                                    class="h4 mt-2"
                                />
                            </b-td>
                        </b-tr>
                    </template>
                </b-tbody>
            </b-table-simple>

            <b-table
                :fields="table.fields"
                :items="table.items"
                class="text-center d-none d-sm-block"
                thead-tr-class="desktop-t"
            >
                <template v-slot:cell(second)="data">
                    <span v-if="typeof data.item.second === 'string'">{{ data.item.second }}</span>
                    <b-icon
                        v-else
                        :class="data.item.second ? 'blue' : 'dark-gray'"
                        :icon="data.item.second ? 'check-circle-fill' : 'x-circle-fill'"
                        class="h4"
                    />
                </template>
                <template v-slot:cell(third)="data">
                    <span v-if="typeof data.item.third === 'string'">{{ data.item.third }}</span>
                    <b-icon
                        v-else
                        :class="data.item.third ? 'blue' : 'dark-gray'"
                        :icon="data.item.third ? 'check-circle-fill' : 'x-circle-fill'"
                        class="h4 text-bold"
                    />
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeOwnerComparison',
    data: () => ({
        table: {
            fields: [
                { key: 'first', label: 'How We Compare' },
                { key: 'second', label: 'Jewelers Mutual' },
                { key: 'third', label: 'Typical Homeowners' }
            ],
            items: [
                {
                    first: 'Loss',
                    second: true,
                    third: false
                },
                {
                    first: 'Theft',
                    second: true,
                    third: true
                },
                {
                    first: 'Damage',
                    second: true,
                    third: 'Not always covered'
                },
                {
                    first: 'Disappearance',
                    second: true,
                    third: false
                },
                {
                    first: 'Flood or Earthquake',
                    second: true,
                    third: 'Not always covered'
                },
                {
                    first: 'Worldwide Travel',
                    second: true,
                    third: true
                },
                {
                    first: 'Out-of-Pocket Cost',
                    second: '$0 deductible option',
                    third: 'Homeowners deductible applies'
                }
            ]
        }
    })
};
</script>

<style lang="scss" scoped>
.padding {
    padding: 22px !important;
}

.blue {
    color: $blue;
}

.dark-gray {
    color: $dark-gray;
}

.table {
    border: 1px solid $gray;

    /deep/ thead {
        background-color: rgba(0, 0, 0, 0.05);

        th {
            padding: 22px;

            @media (min-width: 767px) {
                &:nth-child(even) {
                    border-left: 1px solid $gray;
                    border-right: 1px solid $gray;
                }
            }
        }
    }

    /deep/ tbody {
        @media (min-width: 767px) {
            tr:nth-child(even) {
                background-color: rgba(0, 0, 0, 0.05);
            }

            td:nth-child(even) {
                border-left: 1px solid $gray;
                border-right: 1px solid $gray;
            }
        }

        @media (max-width: 767px) {
            tr:nth-child(odd) {
                background-color: rgba(0, 0, 0, 0.05);
            }

            td:nth-child(odd) {
                border-left: 1px solid $gray;
                border-right: 1px solid $gray;
            }
        }

        tr td {
            padding: 22px;
        }
    }
}

.mobile-small {
    font-size: 12px;
}

/deep/ .mobile-t {
    th {
        width: 50%;
    }
}

/deep/ .desktop-t {
    th {
        width: 33.33%;
    }
}
</style>
