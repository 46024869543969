<template>
    <b-col class="my-2" cols="6" lg="3" @click="selectThisOption">
        <b-card
            :class="{ blue: deductible.selected }"
            class="no-select text-uppercase text-center py-2 pointer"
            :border-variant="deductible.selected ? 'primary' : ''"
        >
            <div class="icon">
                <b-icon v-if="deductible.selected" class="h5" icon="check-circle-fill" />
            </div>
            <div class="mt-2 d-value font-weight-bold">
                <DecimalPrice :price="deductible.Deductible" />
            </div>
            <div class="gray-text deductible">deductible</div>
            <div class="value">
                <DecimalPrice :price="deductible.AnnualPremium" />
            </div>
            <div class="gray-text year">per year</div>
            <div :class="{ sfb: deductible.selected }" class="fake-button mx-2">
                {{ deductible.selected ? 'selected' : 'select' }}
            </div>
        </b-card>
    </b-col>
</template>

<script>
import DecimalPrice from '@/components/Common/DecimalPrice';

export default {
    name: 'DeductibleCard',
    components: { DecimalPrice },
    props: {
        deductible: {
            type: Object,
            required: true
        },
        jewelryIndex: {
            type: Number,
            required: true
        },
        deductibleIndex: {
            type: Number,
            required: true
        }
    },
    methods: {
        selectThisOption() {
            if (!this.deductible.selected) {
                this.$emit('changeSelected', { jewelryIndex: this.jewelryIndex, optionId: this.deductibleIndex });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.border-primary {
    border-color: $blue !important;
}

.card {
    border-radius: 0;
    border-color: $light-gray;
    position: relative;

    .card-body {
        padding: 0;
    }

    .icon {
        position: absolute;
        right: 5px;
        color: $blue;
    }

    .d-value {
        font-size: 14px;
        margin-bottom: $margin-under-element - 10px;
    }

    .value {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: $margin-under-element - 15px;
    }

    .deductible {
        font-size: 12px;
        color: $light-gray;
        margin-bottom: $margin-under-element - 10px;
    }

    .year {
        font-size: 11px;
        font-weight: bold;
        color: $light-gray;
        margin-bottom: $margin-under-element - 10px;
    }

    .fake-button {
        border: 1px solid $blue;
        background-color: $blue;
        color: $white;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sfb {
        background-color: $white;
        color: $blue;
    }

    .blue {
        border-color: $blue !important;
    }
}
</style>
